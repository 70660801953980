<template>
  <div class="container">
    <div class="header">
      <Header></Header>
    </div>
    <div class="nav">
      <Nav :navData="navList" :isActive="nav_id"></Nav>
    </div>
    <div class="content">
      <div v-if="type == 1" class="wCenter">
        <!-- 导航栏 -->
        <div class="navigation">
          <span class="index" @click="jump(1)">首页 > </span>
          <span>证书查询</span>
        </div>
        <div class="find">选择查询的证书</div>
        <!-- 多证书类型 -->
        <div class="more">
          <div class="certficate-act">协会培训证书查询</div>
          <div
            v-for="(cert_item, cert_index) in dataCert"
            :key="cert_index"
            :class="[
              cert_item.id == active_id ? 'certficate-act' : 'certficate',
            ]"
            @click="jumpNewCert(cert_item.url)"
          >
            {{ cert_item.title }}
          </div>
        </div>
        <div class="certficate-find">证书查询</div>
        <!-- 查询框 -->
        <div class="inp">
          <div class="inp-content">
            <div class="inp-title">协会培训证书传查询</div>
            <div class="tips">请至少输入两项查询</div>
            <div class="name">
              <div class="name-title">姓名：</div>
              <div class="name-input">
                <input
                  type="text"
                  v-model="query.name"
                  placeholder="请输入姓名"
                />
              </div>
            </div>
            <div class="name">
              <div class="name-title">身份证号码：</div>
              <div class="name-input">
                <input
                  type="text"
                  v-model="query.identity"
                  placeholder="请输入身份证号码"
                />
              </div>
            </div>
            <div class="name">
              <div class="name-title">证件编号:</div>
              <div class="name-input">
                <input
                  type="text"
                  v-model="query.encoded"
                  placeholder="请输入证件编号"
                />
              </div>
            </div>
            <div class="button">
              <div class="reset" @click="reset()">重置</div>
              <div class="select" @click="sub()">查询</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="wCenter">
        <!-- 导航栏 -->
        <div class="navigation">
          <span class="index" @click="jump(1)">首页 > </span>
          <span class="index" @click="jump(2)">证书查询 > </span>
          <span>证书查询结果</span>
        </div>
        <div class="find">证书查询结果</div>
        <div class="info">
          <div class="info-title">基本信息</div>
          <div class="info-row">
            <div class="info-item default">姓名</div>
            <div class="info-item">{{ certData[0].uname }}</div>
            <div class="info-item default">性别</div>
            <div v-if="certData[0].sex == 1" class="info-item">男</div>
            <div v-else class="info-item">女</div>
          </div>
          <div class="info-row">
            <div class="info-item default">身份证号码</div>
            <div class="info-item">{{ certData[0].identity }}</div>
            <div class="info-item"></div>
            <div class="info-item"></div>
          </div>
        </div>
        <div
          v-for="(info_item, info_index) in certData"
          :key="info_index"
          class="info"
        >
          <div class="info-title">证书名称</div>
          <div class="info-row">
            <div class="info-item default">证书类别</div>
            <div class="info-item">{{ info_item.tname }}</div>
            <div class="info-item default">发证日期</div>
            <div class="info-item">{{ info_item.granttime }}</div>
          </div>
          <div class="info-row">
            <div class="info-item default">证书编号</div>
            <div class="info-item">{{ info_item.encoded }}</div>
            <div class="info-item default">有效期至</div>
            <div class="info-item">{{ info_item.effective }}</div>
          </div>
          <div class="info-row">
            <div class="info-item default">专业</div>
            <div class="info-item">{{ info_item.major }}</div>
            <div class="info-item"></div>
            <div class="info-item"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div>
      <Foot></Foot>
    </div>
  </div>
</template>

<script>
import { getNav, getcertificate, postCertificate } from "@/http/api/home/index";
import Header from "../components/header.vue";
import Nav from "../components/nav.vue";
import Foot from "../components/foot.vue";
export default {
  data() {
    return {
      navList: [],
      active_id: 1,
      title: "",
      dataCert: [],
      query: {
        name: "",
        identity: "",
        encoded: "",
      },
      nav_id: "0",
      type: 1, //1证书查询 2证书查询成功页
      certData: [],
    };
  },
  filters: {},
  components: {
    Header,
    Nav,
    Foot,
  },
  watch: {},
  created() {
    this.getNav();
    this.getcertificate();
  },
  methods: {
    getNav() {
      getNav().then((res) => {
        this.navList = res.data.data;
      });
    },
    //查询证书列表
    getcertificate() {
      getcertificate().then((res) => {
        this.dataCert = res.data.data;
      });
    },

    //重置
    reset() {
      this.query.name = "";
      this.query.identity = "";
      this.query.encoded = "";
    },
    //查询证书
    sub() {
      //三个输入两个就行
      if (this.query.name == "") {
        if (this.query.identity == "" || this.query.encoded == "") {
          alert("请至少填写两项");
          return false;
        }
      }
      if (this.query.identity == "") {
        if (this.query.name == "" || this.query.encoded == "") {
          alert("请至少填写两项");
          return false;
        }
      }
      if (this.query.encoded == "") {
        if (this.query.name == "" || this.query.identity == "") {
          alert("请至少填写两项");
          return false;
        }
      }
      //调用接口
      this.query.superior = this.active_id;
      postCertificate(this.query).then((res) => {
        if (res.data.code == 200) {
          this.type = 2;
          this.certData = res.data.data;
        } else {
          alert("证件编号或身份信息错误，请重新输入");
        }
      });
    },
    jump(type) {
      if (type == 1) {
        //首页
        var url = "/index";
        this.$router.push({ path: url });
      } else {
        this.type = 1;
      }
    },
    jumpNewCert(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  .navigation {
    height: 44px;
    background: #f7f7f7;
    font-size: 16px;
    color: #666666;
    line-height: 44px;
    padding-left: 11px;
    .index {
      cursor: pointer;
    }
  }
  .find {
    margin-top: 14px;
    height: 58px;
    font-size: 20px;
    color: #333333;
    line-height: 58px;
    border-bottom: 2px solid #f7f7f7;
  }
  .info {
    width: 100%;
    height: auto;
    margin-top: 16px;
    .info-title {
      width: 100%;
      height: 50px;
      background: #dcf4f3;
      font-size: 20px;
      color: #005a69;
      line-height: 50px;
      padding-left: 20px;
    }
    .info-row {
      display: flex;
      border-right: 1px solid #dddddd;
      .info-item {
        width: 25%;
        height: 49px;
        border-left: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        font-size: 16px;
        text-align: center;
        color: #333333;
        line-height: 49px;
      }
      .default {
        color: #005a69;
      }
    }
  }
  .more {
    width: 100%;
    height: 50px;
    margin-top: 30px;
    .certficate {
      height: 50px;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      text-align: center;
      display: inline-block;
      padding: 13px 28px 12px 29px;
      background: #f7f7f7;
      margin-right: 30px;
      cursor: pointer;
    }
    .certficate-act {
      height: 50px;
      font-size: 18px;
      font-weight: 500;
      color: #005a69;
      text-align: center;
      display: inline-block;
      padding: 13px 28px 12px 29px;
      background: #dcf4f3;
      margin-right: 30px;
      cursor: pointer;
    }
  }
  .certficate-find {
    width: 100%;
    height: 58px;
    line-height: 58px;
    font-size: 20px;
    color: #333333;
    border-bottom: 2px solid #f7f7f7;
    margin-top: 21px;
  }
  .inp {
    widows: 100%;
    height: 460px;
    .inp-content {
      width: 436px;
      margin: 0px auto;

      .inp-title {
        font-size: 30px;
        font-weight: 500;
        text-align: center;
        color: #005a69;
        height: 73px;
        line-height: 73px;
        margin-top: 24px;
      }
      .tips {
        width: 100%;
        height: 35px;
        background: #f7f7f7;
        font-size: 14px;
        color: #f5a25a;
        line-height: 35px;
        padding-left: 13px;
      }
      .name {
        width: 100%;
        // display: flex;
        margin-top: 10px;
        .name-title {
          width: 113px;
          height: 30px;
          font-size: 16px;
          color: #333333;
          line-height: 30px;
          text-align: left;
        }
        .name-input {
          width: 436px;
          height: 40px;
          input {
            width: 436px;
            height: 40px;
            border: 1px solid #dddddd;
            padding: 11px;
          }
          input::-webkit-input-placeholder {
            font-size: 14px;
            color: #999999;
          }
          input:focus {
            border: 1px solid #059e96;
          }
        }
      }
      .button {
        width: 100%;
        height: 40px;
        display: flex;
        margin-top: 30px;
        .reset {
          width: 251px;
          height: 40px;
          border: 1px solid #005a69;
          border-radius: 2px;
          font-size: 16px;
          color: #005a69;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
        }
        .select {
          width: 251px;
          height: 40px;
          background: linear-gradient(180deg, #0b918b 6%, #076a81);
          border-radius: 2px;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          line-height: 40px;
          margin-left: 46px;
          cursor: pointer;
        }
        .select:hover {
          background: linear-gradient(180deg, #25dad4, #008a9b);
        }
      }
    }
  }
}
</style>
